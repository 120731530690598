#manual {
    background-color: #f8f9fa;
  }
  
  .manual-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    transition: transform 0.3s;
  }
  
  .manual-card:hover {
    transform: translateY(-10px);
  }
  
  .manual-card .card-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .manual-card .card-text p,
  .manual-card .card-text ol,
  .manual-card .card-text ul {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .manual-card .card-text h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  