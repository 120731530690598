.service-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
  }
  
  .service-card .card-body {
    padding: 30px;
  }
  
  .service-card .card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .service-card .card-text {
    font-size: 1rem;
    color: #555;
  }
  