#features {
    position: relative;
    padding: 3rem 0;

    /* Replace with the path to your background image */
}

.firstPage {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.image-container {
    position: relative;
    flex: 1 1 45%;
    /* Adjust as needed */
    max-width: 45%;
    /* Adjust as needed */
    margin: 10px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.image-container:hover {
    transform: scale(1.05);
}

.feature-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    transition: opacity 0.3s ease;
}

.feature-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-container:hover .feature-image::after {
    opacity: 1;
}