.aboutUs {
    position: relative;
    padding: 60px 0;
    color: #333;
}
  /* Styles for the provided div */
.aa {
    text-align: left;
  margin-left: 40px;

  }
  
  /* Styles for the h2 element */
  .h22 {
font-weight: bold;
    font-size: 2.5rem;
    color: #483285;
    font-family: Arial, sans-serif;
    margin-top: 50px;
   
  }
  
  /* Styles for the p element */
  .h22 p {
    font-size: 1.2rem;
    color: #6c757d;
    font-family: Arial, sans-serif;
    margin-bottom: 0;
  }
  
  .header {
    text-align: center;
    margin-bottom: 40px;
    color: white;
  }
  
  .header h2 {
    font-size: 3rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .header p {
    font-size: 1.3rem;
    color: #dcdcdc;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
  }
  
  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .imageCol {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
  }
  
  .image {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .textCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
  
  .card {
    margin-bottom: 20px;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    font-size: 1.8rem;
    color: #483285;
    font-family: 'Montserrat', sans-serif;
  }
  
  .card-text {
    font-size: 1.1rem;
    color: #555;
    font-family: 'Open Sans', sans-serif;
  }
  
  .card ul {
    padding-left: 20px;
  }
  
  .card ul li {
    margin-bottom: 10px;
  }
  
  .card ul li strong {
    color: #483285;
  }
  
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
      text-align: center;
    }
  
    .textCol {
      padding: 0;
    }
  
    .card-title {
      margin-top: 20px;
    }
  }
  