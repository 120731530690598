/* WhatsAppIcon.css */
.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.whatsapp-icon:hover {
    background-color: #128c7e;
    text-decoration: none;
}

.whatsapp-icon i {
    font-size: 24px;
}