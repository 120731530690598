.me-auto {
  width: 100%;
  justify-content: flex-end;
}

/* Navbar link styles */
.nav-link {
  position: relative;
  color: #ffffff;
  /* White color for better contrast */
  font-weight: bold;
  text-decoration: none;
  margin: 0 15px;
  padding: 10px 0;
  transition: color 0.3s ease-in-out;
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffcc00;
  /* Customize the underline color */
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.nav-link:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.nav-link:hover {
  color: #ffcc00;
  /* Customize the hover text color */
}

/* Additional styling for a professional look */
.navbar {
  background-color: #151B54;
  /* Navbar background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffcc00;
  /* Brand color */
}

.navbar-toggler {
  border: none;
}

@media (max-width: 768px) {
  .navbar-collapse {
    background-color: #151B54;
    /* Mobile view background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Mobile view shadow */
  }

  .nav-link {
    margin: 10px 0;
  }
}


/* styles.css */
/* styles.css */
.image-shadow {
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
  transition: transform 0.3s ease, filter 0.3s ease;
}

.image-shadow:hover {
  transform: scale(1.05);
  filter: drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.5));
}

/* styles.css */
/* styles.css */
@keyframes show-word {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide-word {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show-letter-by-letter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.word-container span {
  display: inline-block;
  opacity: 0;
}

.word-container span.show {
  animation: show-letter-by-letter 0.5s forwards;
}