.scrolling-wrapper {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    gap: 20px;
}

.scrolling-card {
    flex: 0 0 auto;
    scroll-snap-align: start;
    width: 300px;
    /* Adjust width as needed */
}

.scrolling-card img {
    width: 100%;
    height: auto;
}