/* ServiceAnimation.css */
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .service-section {
    animation: slideInFromLeft 1s ease-in-out;
  }
  